import { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config';

export const usePhysicians = (filters) => {
  const [physicians, setPhysicians] = useState([]);

  const getPhysicians = () => axios.get(`${config.api.baseUrl}/v2/physician`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('userToken')}`
      }
    })
    .then(({ data }) => {
      const _physicians = data.physicians;

      setPhysicians(_physicians);
      return _physicians;
    });

  useEffect(() => {
    getPhysicians();
  }, [])

  return { physicians, getPhysicians };
}