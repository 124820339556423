import React, { useEffect, useState } from 'react'
import { Input, Select, Typography, message } from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components'

import { Template } from "./components/Template"

import { lookUp } from '../../../../api/LookUpAPI'
import { useTelemedicineCategoryContext } from '../../../../context/TelemedicineCategoryContext';

const { Text } = Typography

const TemplatesContainer = styled.div`
    width: 100%;
    padding: 10px;
    height: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
`

const TemplateSearch = ({
    templateFilters,
    setTemplateFilters
}) => {

    const { telemedicineCategories } = useTelemedicineCategoryContext()
    const [providers, setProviders] = useState([])

    useEffect(() => {
        lookUp("physician")
            .then(({ data }) => {
                setProviders(data.payload)
            })
            .catch(e => console.log(e))
    }, [])

    const handleUpdateTemplateFilters = update => {
        const filters = { ...templateFilters }
        setTemplateFilters({ ...filters, ...update })
    }

    return (
        <div style={{
            height: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '10px',
        }}>
            <Input
                type="text"
                style={{ width: '500px', margin: '10px 0' }}
                placeholder="Search prescription template names.."
                prefix={<SearchOutlined style={{ color: '#e2e7e9' }} />}
                onChange={e => handleUpdateTemplateFilters({ search: e.target.value })}
            />
            <div>
                <Text>Owner:</Text>
                <Select
                    label="Owner"
                    name="ownerId"
                    defaultValue="Admin"
                    style={{ width: '225px', marginLeft: '10px' }}
                    onChange={value => handleUpdateTemplateFilters({ owner: value })}

                >
                    {[{ _id: 'Admin', firstName: 'Admin' }, ...providers].map(provider => {
                        const providerName = provider.lastName ? `${provider.lastName}, ${provider.firstName}` : provider.firstName
                        return <Select.Option value={provider._id}>{providerName}</Select.Option>
                    })}
                </Select>
            </div>
            <div>
                <Text>Category:</Text>
                <Select
                    allowClear
                    name="categoryIds"
                    style={{ width: '225px', marginLeft: '10px' }}
                    placeholder="Telemedicine Category"
                    onChange={val => handleUpdateTemplateFilters({ categoryIds: val })}
                >
                    {telemedicineCategories.map(category => (
                        <Select.Option value={category._id}>{category.name}</Select.Option>
                    ))}
                </Select>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Text>Sort:</Text>
                <Select style={{ width: '140px' }} value={templateFilters.sortBy} onChange={val => handleUpdateTemplateFilters({ sortBy: val })}>
                    <Select.Option value="title">Template Title</Select.Option>
                    <Select.Option value="medication.drugName">Medication Name</Select.Option>
                    <Select.Option value="createdAt">Created Date</Select.Option>
                    <Select.Option value="updatedAt">Last Updated</Select.Option>
                </Select>
                <Select style={{ width: '80px' }} value={templateFilters.sortDirection} onChange={val => handleUpdateTemplateFilters({ sortDirection: val })}>
                    <Select.Option value="asc">Asc</Select.Option>
                    <Select.Option value="desc">Desc</Select.Option>
                </Select>
            </div>
        </div>
    )
}

export const PrescriptionTemplates = ({
    templates = [],
    selectTemplate,
    deleteTemplate,
    handleFinish,
    templateFilters,
    setTemplateFilters,
}) => {

    const handleDeleteTemplate = async templateId => {
        if (window.confirm("Are you sure you wish to delete this template?")) {
            try {
                await deleteTemplate(templateId)
                handleFinish()
            } catch (e) {
                message.error(e.message)
            }
        }
    }

    return (
        <div>
            <TemplateSearch
                templateFilters={templateFilters}
                setTemplateFilters={setTemplateFilters}
            />
            <TemplatesContainer>
                {templates
                    .filter(template => template.type === 'prescription')
                    .map(template => (
                        <Template
                            key={template._id}
                            template={template}
                            selectTemplate={selectTemplate}
                            handleDeleteTemplate={handleDeleteTemplate}
                        />
                    )
                    )}
            </TemplatesContainer>
        </div>
    )
}

