import React, { useState, useRef } from "react";
import { Modal, Button, Spin, message } from "antd";
import { CSVLink } from 'react-csv';
import styled from "styled-components";

import { 
    bulkReassign, 
    archiveCases,
    softDeleteCases,
    releaseCasesToQueue,
    bulkUpdateCaseStatus,
    bulkUpdateCaseConsultationType
} from '../../api/CasesAPI'
import { BulkActionEnum } from "../../constants/enums"

import BulkReassign from './BulkReassign'
import BulkUpdateStatus from './BulkUpdateStatus'
import BulkUpdateConsultationType from './BulkUpdateConsultationType'
import BulkUpdateResultsComponent from './BulkUpdateResultsComponent'

const {
    ARCHIVE,
    RESTORE,
    DELETE,
    RELEASE,
    REASSIGN,
    STATUS,
    CONSULTATION_TYPE,
    EXPORT
  } = BulkActionEnum

const StyledModal = styled(Modal)`
  text-transform: capitalize;

  & * {
    text-transform: capitalize;
  }

  & .ant-modal-header {
    border-bottom-color: #f1f1f1;
  }

  & .ant-modal-footer {
    border-top-color: #f1f1f1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .ant-alert {
    background: #f1f1f1;
    border: none;
    margin-bottom: 20px;
  }

  & .ant-table-row-selected {
      color: #fff;
  }


  & .ant-alert {
    margin-bottom: 0;
  }
`

const StyledSpin = styled(Spin)`
`

const ButtonGroup = styled.div`
  margin-left: auto;
`

const BulkUpdateModal = ({
    selectedCases,
    setSelectedCases,
    bulkAction,
    setBulkAction,
    fetchCases
}) => {
    const [bulkUpdateParams, setBulkUpdateParams] = useState({})
    const [successCount, setSuccessCount] = useState(null)
    const [resultMessage, setResultMessage] = useState(null)
    const [failures, setFailures] = useState(null)
    const [loading, setLoading] = useState(false)
    const [submitDisabled, setSubmitDisabled] = useState(false)
    const selectedCaseIds = selectedCases.map(_case => _case.caseId);
    const exportedCsvLinkRef = useRef(null);

    const confirmMessage = action => <p>{`Confirm ${action} cases?`}</p>

    const bulkActions = {
        [ARCHIVE]: {
            title: 'Archive Cases',
            component: confirmMessage(ARCHIVE),
            fn: params => archiveCases({ ...params, archive: true })
        },
        [RESTORE]: {
            title: 'Restore Cases',
            component: confirmMessage(RESTORE),
            fn: params => archiveCases({ ...params, archive: false })
        },
        [DELETE]: {
            title: 'Delete Cases',
            component: confirmMessage(DELETE),
            fn: softDeleteCases
        },
        [RELEASE]: {
            title: 'Conform release cases to queue?',
            component: <h4>This will reset case statuses to either <strong>pending schedule</strong> or <strong>pending prescription</strong></h4>,
            fn: releaseCasesToQueue
        },
        [REASSIGN]: {
            title: 'Reassign Cases',
            component: <BulkReassign setBulkUpdateParams={setBulkUpdateParams} selectedCases={selectedCaseIds} setSubmitDisabled={setSubmitDisabled}/>,
            fn: bulkReassign
        },
        [STATUS]: {
            title: 'Update Case Status',
            component: <BulkUpdateStatus setBulkUpdateParams={setBulkUpdateParams} />,
            fn: bulkUpdateCaseStatus
        },
        [CONSULTATION_TYPE]: {
            title: 'Change Case Consultation Type',
            component: <BulkUpdateConsultationType setBulkUpdateParams={setBulkUpdateParams}/>,
            fn: bulkUpdateCaseConsultationType
        },
        [EXPORT]: {
            title: 'Export Cases to CSV',
            component: <>
                {confirmMessage(EXPORT)}
                <CSVLink data={selectedCases} filename={`${Date.now()}_exported-cases.csv`} target='_blank' ref={exportedCsvLinkRef} />
            </>,
            fn: async ({ caseIds }) => {
                exportedCsvLinkRef.current.link.click();
                return { data: { results: Array(caseIds.length).fill({ success: true }) } }
            }
        }
    }

    const createSubmitFn = fn => {
        return async () => {
            setLoading(true)
            try {
                const response = await fn({ ...bulkUpdateParams, caseIds: selectedCaseIds })
                const data = response.data
                
                if (data.results) {
                    const numSuccess = data.results.filter(result => result.success).length
                    const failed = data.results.filter(result => !result.success)
                    setSuccessCount(numSuccess)
                    setFailures(failed)
                } else if (data.message) {
                    setResultMessage(data.message)
                }
            } catch (e) {
                console.log(e)
                message.error("Error updating cases");
            } finally {
                setLoading(false)
            }
        };
    }
    
    const cleanUp = () => {
        setFailures(null)
        setSuccessCount(null)
        setBulkAction(null)
        setSelectedCases([])
        fetchCases()
    }

    const CallToActionFooter = () => (
        <ButtonGroup>
            <Button key="back" onClick={() => setBulkAction(null)}>Cancel</Button>
            <Button key="submit" type="primary" disabled={submitDisabled} onClick={createSubmitFn(bulkActions[bulkAction].fn)}>{bulkAction || 'Update'}</Button>
        </ButtonGroup>
    )

    const ConfirmationFooter = () => (
        <ButtonGroup>
            <Button key="submit" type="primary" onClick={cleanUp}>Ok</Button>
        </ButtonGroup>
    )

    const actionCompleted = successCount || failures || resultMessage

    return (
        <StyledModal
            title={`${bulkActions[bulkAction].title} (${selectedCaseIds.length} case${selectedCaseIds.length > 1 ? 's' : ''} selected)`}
            visible={selectedCaseIds?.length > 0 && bulkAction}
            closable={true}
            onCancel={() => setBulkAction(null)}
            destroyOnClose
            zIndex={1010}
            footer={[actionCompleted ? <ConfirmationFooter /> : <CallToActionFooter/>]}
        >
            <StyledSpin spinning={loading}>
                { actionCompleted && <BulkUpdateResultsComponent successCount={successCount} failures={failures} message={resultMessage}/> }
                { bulkAction && !actionCompleted && bulkActions[bulkAction].component }
            </StyledSpin>
        </StyledModal>
    )
}

export default BulkUpdateModal
