//import packages
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Layout,
  Table,
  Row,
  Col,
  Typography,
  Tabs,
  Form,
  Input,
  Spin,
  message,
  Button,
  Modal,
  Select,
  DatePicker,
  Checkbox,
} from "antd";
import {
  LoadingOutlined,
  EditOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import {
  FileTextOutlined,
} from "@ant-design/icons";

//import components
import CaseFilter from "./CaseFilter";
import CasePatientEditDetails from "./CasePatientEditDetails";
import ConsolidatedCaseDetails from "./ConsolidatedCaseDetails";
import BulkUpdateModal from '../../components/BulkUpdate/BulkUpdateModal'
import UserRolesAndRightsHOComponent from "../../components/UserRolesAndRightsHOComponent/UserRolesAndRightsHOComponent";

//import other files and API function
import { 
  getCases, 
  scheduleAppointment
} from "../../api/CasesAPI";

import { lookUp } from "../../api/LookUpAPI";
import { BulkActionEnum } from "../../constants/enums"
import {
  dateTimeFormatter,
  unixDateTimeFormatter,
} from "../../utils/dateFormatter";
import authorization from "../../auth/authorization";

import { sorters } from '../../utils/sorters'
import moment from "moment";

const { Content } = Layout;
const { TabPane } = Tabs;
const { Search } = Input;

const {
  ARCHIVE,
  RESTORE,
  DELETE,
  RELEASE,
  REASSIGN,
  STATUS,
  CONSULTATION_TYPE,
  EXPORT
} = BulkActionEnum

const StyledTabWrapper = styled.div`
  width: 100%;
  max-width: 1480px;
  margin: 24px auto;
  @media (max-width: 1800px) {
    max-width: 1300px;
  }

  @media (max-width: 1550px) {
    max-width: 1080px;
  }
  @media (max-width: 1300px) {
    max-width: 950px;
  }

  @media (max-width: 1199px) {
    max-width: 780px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 712px;
  }
  @media (max-width: 720px) {
    max-width: 372px;
  }
`;

const StyledComponentWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 1480px;
  padding: 24px 28px;
  @media (max-width: 1800px) {
    max-width: 1300px;
  }
  @media (max-width: 1550px) {
    max-width: 1150px;
  }
  @media (max-width: 1300px) {
    max-width: 950px;
  }
  @media (max-width: 1199px) {
    max-width: 780px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 712px;
  }
  @media (max-width: 720px) {
    max-width: 372px;
  }
`;

const StyledTabs = styled(Tabs)`
  > .ant-tabs-bar {
    margin: unset;
  }
  > .ant-tabs-bar
    > .ant-tabs-nav-container
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-scroll
    > .ant-tabs-nav
    > div
    > .ant-tabs-tab-active {
    background: #ffffff;
    border-bottom: unset;
  }
`;

const StyledTable = styled(Table)`
  > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    > tr.ant-table-row
    > :nth-child(1) {
    padding-left: unset;
  }
  > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table
    > .ant-table-container
    > .ant-table-content
    > table
    > .ant-table-tbody
    > tr.ant-table-row
    > td {
    cursor: pointer;
  }
`;

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const SearchFilterWrapper = styled.div`
  display: flex;
`;

const ColourBorder = styled.div`
  border-left: solid 5px ${(props) => props.inputColor || "white"};
  padding-left: 5px;
`;

const SearchFilter = styled(Search)`
  > .ant-input-suffix .ant-input-clear-icon {
    color: unset;
  }
`;

const StyledSelect = styled(Select)`
  width: 200px;
  margin-bottom: 20px;
`

const timeZoneList = moment.tz.names();

const CasesComponent = ({ userType }) => {
  const [loading, setLoading] = useState(false);
  const [cases, setCases] = useState([]);
  const [caseId, setCaseId] = useState("");
  const [editViewPatientDetails, setEditViewPatientDetails] = useState(false);
  const [clients, setClients] = useState([]);
  const [states, setStates] = useState([]);
  const [telemedicineCategory, setTelemedicineCategory] = useState([]);
  const [caseStatus, setCaseStatus] = useState([]);
  const [totalCase, setTotalCase] = useState(0);
  const [filterParams, setFilterParams] = useState();
  const [tablePageSize, setTablePageSize] = useState(localStorage.getItem('pageSize') || 100);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewCaseDetails, setCaseDetailsModal] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [scheduleModalIsVisible, setScheduleModalIsVisible] = useState(false);
  const [physicianOptions, setPhysicianOptions] = useState([]);
  const [selectedPhysician, setSelectedphysician] = useState();
  const [selectedCaseId, setSelectedCaseId] = useState();
  const [scheduleForm] = Form.useForm();
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [bulkAction, setBulkAction] = useState(null)
  const [selectedCases, setSelectedCases] = useState([])
  const [filterForm] = Form.useForm();


  const [forceCaseDetailsUpdate, setForceCaseDetailsUpdate] = React.useState();
  let history = useHistory();

  const forceUpdate = React.useCallback(() => setForceCaseDetailsUpdate({}), []);

  const [columns, setColumns] = useState([
    {
      title: "Case Id",
      width: 280,
      fixed: "left",
      dataIndex: "",
      key: "case-id",
      render: (eachCase) => (
        <ColourBorder inputColor={eachCase.colourCode}>
          {eachCase.caseId ? eachCase.caseId : ""}
        </ColourBorder>
      ),
      sorter: (a, b) => sorters.alphanumeric(a.caseId, b.caseId),
    },
    {
      title: "Category",
      width: 250,
      dataIndex: "categoryName",
      key: "category",
      sorter: (a, b) => sorters.alphanumeric(a.categoryName, b.categoryName),
    },
    {
      title: "Physician Name",
      width: 240,
      dataIndex: "physicianName",
      key: "physician-name",
      sorter: (a, b) => sorters.alphanumeric(a.physicianName, b.physicianName),
    },
    {
      title: "Patient Name",
      width: 240,
      dataIndex: "patientName",
      key: "patient-name",
      sorter: (a, b) => sorters.alphanumeric(a.patientName, b.patientName),
    },
    {
      title: "Patient Email",
      width: 370,
      dataIndex: "patientEmail",
      key: "patient-email",
      sorter: (a, b) => sorters.alphanumeric(a.patientEmail, b.patientEmail),
    },
    {
      title: "Patient Id",
      width: 280,
      dataIndex: "patientId",
      key: "patient-id",
      sorter: (a, b) => sorters.alphanumeric(a.patientId, b.patientId),
    },
    {
      title: "Wheel Consult Id",
      width: 350,
      dataIndex: "wheel_consultId",
      key: "wheel-consult-id",
      sorter: (a, b) => sorters.alphanumeric(a.wheel_consultId, b.wheel_consultId),
    },
    {
      title: "Created Date",
      width: 230,
      dataIndex: "",
      key: "data",
      sorter: (a, b) => sorters.dates(new Date(a.createdDate), new Date(b.createdDate)),
      render: (eachCase) => (
        <>
          {eachCase.createdDate ? dateTimeFormatter(eachCase.createdDate) : ""}
        </>
      ),
    },
    {
      title: "Updated Date",
      width: 230,
      dataIndex: "",
      key: "updated_Ts",
      sorter: (a, b) => sorters.dates(new Date(a.updated_ts), new Date(b.updated_ts)),
      render: (eachCase) => (
        <>
          {eachCase.updated_ts
            ? unixDateTimeFormatter(eachCase.updated_ts)
            : ""}
        </>
      ),
    },
    {
      title: "Consult Type",
      width: 230,
      dataIndex: "",
      key: "consulationType",
      sorter: (a, b) => sorters.alphanumeric(a.consultationType, b.consultationType),
      render: (eachCase) => (
        <>
          {eachCase.consultationType
            ? eachCase.consultationType.toUpperCase()
            : ""}
        </>
      ),
    },
    {
      title: "Status",
      width: 340,
      dataIndex: "",
      key: "case-status",
      sorter: (a, b) => sorters.alphanumeric(a.status, b.status),
      render: (eachCase) => (
        <>
          {eachCase.statusDisplayName
            ? eachCase.statusDisplayName.toUpperCase()
            : ""}
        </>
      ),
    },
    {
      title: "Assigned Time",
      width: 230,
      dataIndex: "",
      key: "assigned-time",
      sorter: (a, b) => sorters.dates(new Date(a.assignedTime), new Date(b.assignedTime)),
      render: (eachCase) => (
        <>
          {eachCase.assignedTime
            ? dateTimeFormatter(eachCase.assignedTime)
            : "NA"}
        </>
      ),
    },
    {
      title: "Assigned Group",
      width: 250,
      dataIndex: "",
      sorter: (a, b) => sorters.alphanumeric(a.assignedGroupName, b.assignedGroupName),
      key: "assigned-group",
      render: (eachCase) => (
        <>{eachCase.assignedGroupName ? eachCase.assignedGroupName : "NA"}</>
      ),
    },
    {
      title: "Time of Group Assignment",
      width: 220,
      dataIndex: "",
      key: "assigned-group-time",
      sorter: (a, b) => sorters.dates(new Date(a.assignedTime), new Date(b.assignedTime)),
      render: (eachCase) => (
        <>
          {eachCase.groupAssignedTime
            ? dateTimeFormatter(eachCase.groupAssignedTime)
            : "NA"}
        </>
      ),
    },
  ]);

  const setupReassignModal = caseId => {
    setSelectedCases([caseId])
    setBulkAction(REASSIGN)
  }

  const updateColumnsInListing = () => {
    const clonnedColumns = columns.slice(0);
    if (authorization.isAllowed(userType, "getCasesDetails")) {
      clonnedColumns.push({
        title: "View Details",
        dataIndex: "",
        width: 150,
        key: "t",
        align: "center",
        render: (caseView, record) => (
          <Button
            shape="circle"
            icon={<FileTextOutlined />}
            onClick={(event) => {
              event.stopPropagation();
              onCaseDetailsViewClick(event, caseView);
            }}
          />
        ),
      });
    }
    if (authorization.isAllowed(userType, "updateCase")) {
      clonnedColumns.push({
        title: "Edit Patient",
        dataIndex: "",
        width: 150,
        key: "e",
        align: "center",
        render: (caseDetail, record) => (
          <Button
            shape="circle"
            icon={<EditOutlined />}
            onClick={(event) => {
              event.stopPropagation();
              onPatientEditIconClick(caseDetail.caseId);
            }}
          />
        ),
      });
    }
    setColumns([...clonnedColumns]);
  };

  const initCallsForComponent = () => {
    updateColumnsInListing();
  };

  const fetchCaseStatus = () => {
    lookUp("case-status").then((res) => {
      if (res.data && res.data.payload) {
        const statusData = res.data.payload.map((eachStatus) => ({
          ...eachStatus,
          key: eachStatus._id,
        }));

        setCaseStatus(statusData);
      }
    });
  };

  const fetchStates = () => {
    lookUp("state").then((res) => {
      if (res.data && res.data.payload) {
        const stateData = res.data.payload.map((eachState) => ({
          ...eachState,
          key: eachState._id,
        }));
        setStates(stateData);
      }
    });
  };

  const fetchClients = () => {
    lookUp("client").then((res) => {
      if (res.data && res.data.payload) {
        const clientData = res.data.payload.map((eachClient) => ({
          ...eachClient,
          key: eachClient._id,
        }));
        setClients(clientData);
      }
    });
  };

  const fetchTelemedicineCategory = () => {
    lookUp("tele-medicine-category").then((res) => {
      if (res.data && res.data.payload) {
        const stateData = res.data.payload.map((eachState) => ({
          ...eachState,
          key: eachState._id,
        }));
        setTelemedicineCategory(stateData);
      }
    });
  };

  const fetchCases = () => {
    setLoading(true);
    let page = currentPage;
    let size = tablePageSize;
    let storedPageSize = window.localStorage.getItem("pageSize");
    let storedPage = window.localStorage.getItem("currentPage");

    if (storedPageSize) {
      setTablePageSize(storedPageSize);
      size = storedPageSize;
    }
    if (storedPage) {
      setCurrentPage(storedPage);
      page = storedPage;
    }

    const paramsData = {
      index: page,
      "order-newest": true,
      "case-count": size
    };

    getCases(paramsData)
      .then((res) => {
        if (res.data && res.data.payload.data) {
          setTotalCase(res.data.payload.count);
          const caseData = res.data.payload.data.map((eachCase) => ({
            ...eachCase,
            key: eachCase.caseId,
          }));
          setCases(caseData);
          message.success("Successfully loaded Case List");
        } else {
          message.error("Unable to load Case List. Please try again!");
        }
        setLoading(false);
      })
      .catch((error) => {
        message.error("Unable to load Case List. Please try again!");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchStates();
    fetchCaseStatus();
    fetchClients();
    fetchTelemedicineCategory(); // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  useEffect(initCallsForComponent, [userType]);

  const onTableRowClick = (event, record) => {
    setCaseId(record.key);
    setCaseDetailsModal(true);
  };

  const onCaseDetailsViewClick = (event, record) => {
    setCaseId(record.key);
    history.push(`/cases/${record.key}`);
  };

  const onPatientEditIconClick = (id) => {
    setCaseId(id);
    setEditViewPatientDetails(true);
  };

  const handleSearchValueChange = (value) => {
    const filterData = {};
    filterData["search-term"] = value;
    setFilterParams(filterData);
    filterData["order-newest"] = true;
    filterData["case-count"] = 10;
    setTablePageSize(10);
    onFilterChange(filterData);
    setCurrentPage(1);
  };

  const onFilterChange = (filterData) => {
    setSelectedCases([])
    setLoading(true);
    getCases(filterData)
      .then((res) => {
        if (res.data && res.data.payload.data) {
          setTotalCase(res.data.payload.count);
          const caseData = res.data.payload.data.map((eachCase) => ({
            ...eachCase,
            key: eachCase.caseId,
          }));
          setCases(caseData);
          message.success("Successfully loaded Case List");
        } else {
          message.error("Unable to load Case List. Please try again!");
        }
        setLoading(false);
      })
      .catch((error) => {
        message.error(error.response?.data?.message);
        setLoading(false);
      });
  };

  const clearFilter = () => {
    localStorage.removeItem('caseFilters');
    filterForm.resetFields();
    fetchCases();
    setFilterParams();
    setTablePageSize(100);
    setCurrentPage(1);
  };

  const setFilterDataSource = (paramsSource) => {
    setLoading(true);
    setCurrentPage(1);
    setFilterParams(paramsSource);
    paramsSource["order-newest"] = true;
    paramsSource["index"] = 1;
    paramsSource["case-count"] = tablePageSize;
    onFilterChange(paramsSource);
  };

  const onChangePage = (value, pageSize) => {
    setLoading(true);
    setCurrentPage(value);
    const paramsData = filterParams ? filterParams : {};
    paramsData["case-count"] = pageSize;
    paramsData["order-newest"] = true;
    paramsData["index"] = value;
    onFilterChange(paramsData);
    window.localStorage.setItem("currentPage", value);
  };

  const onPageSizeChange = (current, size) => {
    onChangePage(1, size);
    setTablePageSize(size);
    setCurrentPage(1);
    window.localStorage.setItem("pageSize", size);
  };

  const handleOnClickChangeCaseStatusBtn = (caseId) => {
    setSelectedCaseId(caseId);
    setShowChangeStatusModal(true);
  }

  const onChangePhysicianSelect = (value) => {
    setSelectedphysician(value);
  };

  return (
    <>
      <Content>
        <StyledTabWrapper>
          <StyledTabs type="card">
            <TabPane tab="All" key="1">
              <StyledComponentWrapper>
                <Spin
                  spinning={loading}
                  size="large"
                  indicator={<LoadingOutlined />}
                >
                  <Row>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Title level={2}>All Cases</Title>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form name="search-form" layout={"vertical"}>
                        <Form.Item rules={[{}]}>
                          <SearchFilterWrapper>
                            <SearchFilter
                              size="large"
                              allowClear={true}
                              placeholder={"Search Cases"}
                              // onChange={handleSearchInputChange}
                              onSearch={(value) =>
                                handleSearchValueChange(value)
                              }
                            />
                          </SearchFilterWrapper>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                  <CaseFilter
                    clients={clients}
                    states={states}
                    telemedicineCategory={telemedicineCategory}
                    caseStatus={caseStatus}
                    onFilter={setFilterDataSource}
                    clearFilter={clearFilter}
                    form={filterForm}
                  />
                  <Row>
                    <StyledSelect
                      defaultValue="Actions"
                      value={"Actions"}
                      disabled={selectedCases?.length === 0}
                      onChange={action => setBulkAction(action)}
                    >
                      { authorization.isAllowed(userType, "assignPhysician") && <Select.Option key={REASSIGN} value={REASSIGN}>Reassign</Select.Option> }
                      { authorization.isAllowed(userType, "caseStatusModification") && <Select.Option key={STATUS} value={STATUS}>Change Status</Select.Option> }
                      { authorization.isAllowed(userType, "caseConsultationTypeModification") && <Select.Option key={CONSULTATION_TYPE} value={CONSULTATION_TYPE}>Change Consultation Type</Select.Option> }
                      { authorization.isAllowed(userType, "caseStatusModification") && <Select.Option key={RELEASE} value={RELEASE}>Release Cases to Queue</Select.Option> }
                      { authorization.isAllowed(userType, "caseStatusModification") && !filterForm.getFieldValue().archived && <Select.Option key={ARCHIVE} value={ARCHIVE}>Archive</Select.Option> }
                      { authorization.isAllowed(userType, "caseStatusModification") && filterForm.getFieldValue().archived && <Select.Option key={RESTORE} value={RESTORE} >Restore</Select.Option> }
                      { authorization.isAllowed(userType, "caseStatusModification") && <Select.Option key='Delete' value={DELETE}>Delete</Select.Option> }
                      <Select.Option key={EXPORT} value={EXPORT}>Export to CSV</Select.Option>
                    </StyledSelect>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <StyledTable
                        onRow={(record) => {
                          return {
                            onClick: (event) => {
                              onTableRowClick(event, record);
                            }, // capture click on row
                          };
                        }}
                        columns={columns}
                        dataSource={cases}
                        rowSelection={{
                          selectedRowKeys: selectedCases.map(_case => _case.caseId),
                          onChange: (_, selectedRows) => setSelectedCases(selectedRows)
                        }}
                        pagination={{
                          total: totalCase,
                          showTotal: (totalCase) => `Total ${totalCase} Cases`,
                          current: currentPage,
                          pageSize: tablePageSize,
                          pageSizeOptions: ["5", "10", "20", "50", "100", "500"],
                          showSizeChanger: true,
                          onChange: onChangePage,
                          onShowSizeChange: onPageSizeChange,
                        }}
                        scroll={{ x: 720 }}
                      />
                    </Col>
                  </Row>
                </Spin>
              </StyledComponentWrapper>
            </TabPane>
          </StyledTabs>
        </StyledTabWrapper>
        {caseId && viewCaseDetails && (
          <ConsolidatedCaseDetails
            caseId={caseId}
            viewModal={viewCaseDetails}
            closeModal={setCaseDetailsModal}
            setupReassignModal={setupReassignModal}
            handleOnClickChangeCaseStatusBtn={handleOnClickChangeCaseStatusBtn}
            forceUpdate={forceCaseDetailsUpdate}
            setBulkAction={setBulkAction}
            setSelectedCases={setSelectedCases}
            setVisible={() => setBulkAction(null)} 
          />
        )}

        {caseId && editViewPatientDetails && (
          <CasePatientEditDetails
            caseId={caseId}
            viewPatientEditModal={editViewPatientDetails}
            closePatientEditModal={setEditViewPatientDetails}
          />
        )}
        <Modal
          title='Schedule a Consultation'
          visible={scheduleModalIsVisible}
          okText='Confirm'
          closable={false}
          okButtonProps={{ disabled: fetchLoading }}
          cancelButtonProps={{ disabled: fetchLoading }}
          onOk={scheduleForm.submit}
          onCancel={() => {
            scheduleForm.resetFields();
            setScheduleModalIsVisible(false)
          }}
          destroyOnClose
          zIndex={1010}
        >
          <Spin spinning={fetchLoading}>
            <Form
              form={scheduleForm}
              layout='vertical'
              initialValues={{ timeZone: moment.tz.guess() }}
              onValuesChange={({ timeZone, startTime }, values) => {
                if (timeZone) {
                  scheduleForm.setFieldsValue({
                    startTime: moment(values.startTime).tz(timeZone),
                    endTime: moment(values.endTime).tz(timeZone),
                  })
                }

                if (startTime) {
                  const strippedStartTime = moment(startTime).set({
                    minutes: startTime.minutes() % 10 < 5
                      ? startTime.minutes() - (startTime.minutes() % 10)
                      : startTime.minutes() + (10 - (startTime.minutes() % 10)),
                    seconds: 0,
                    milliseconds: 0
                  });
                  scheduleForm.setFieldsValue({
                    startTime: strippedStartTime,
                    endTime: moment(strippedStartTime).add({ minutes: 10 })
                  });
                }
              }}
              onFinish={(values) => {
                const data = {
                  physicianId: values.physician,
                  startTime: values.startTime.toISOString(),
                  endTime: values.endTime.toISOString(),
                  notifyPatient: !!values.notifyPatient
                }

                setFetchLoading(true)
                return scheduleAppointment(caseId, data)
                  .then(() => {
                    scheduleForm.resetFields();
                    setScheduleModalIsVisible(false);
                    message.success('Scheduled appointment successfully');
                  })
                  .then(fetchCases)
                  .catch(() => message.error('Unable to schedule the appointment'))
                  .finally(() => setFetchLoading(false));
              }}
            >
              <Form.Item name='timeZone' label='Time Zone'>
                <Select
                  showSearch
                  className="timezone-select"
                  placeholder="Select a time zone"
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  optionFilterProp="children"
                >
                  {timeZoneList.map((tz) => (
                    <Select.Option key={tz} value={tz}>{tz}</Select.Option>)
                  )}
                </Select>
              </Form.Item>
              <Form.Item name='physician' label='Physician' rules={[{ required: true }]}>
                <Select
                  showSearch
                  style={{ width: "60%" }}
                  options={physicianOptions}
                  placeholder='Select a physician'
                  onChange={(value) => onChangePhysicianSelect(value)}
                  optionFilterProp='label'
                  filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
              </Form.Item>
              <Form.Item name='startTime' label='Start Time' rules={[{ required: true }]}>
                <DatePicker format='MMM DD YYYY hh:mmA' minuteStep={10} showSecond={false} showTime use12Hours />
              </Form.Item>
              <Form.Item name='endTime' label='End Time' rules={[{ required: true }]}>
                <DatePicker format='MMM DD YYYY hh:mmA' minuteStep={10} showSecond={false} showTime use12Hours disabled />
              </Form.Item>
              <Form.Item
                name='notifyPatient'
                valuePropName='checked'
                label='Notify Patient?'
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  alignItems: 'center',
                  justifyContent: 'flex-end'
                }}
              >
                <Checkbox
                  style={{
                    marginRight: 10,
                    paddingBottom: 8,
                    flexGrow: 'unset'
                  }}
                />
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
        
        { bulkAction && (
          <BulkUpdateModal
            selectedCases={selectedCases}
            setSelectedCases={setSelectedCases}
            bulkAction={bulkAction}
            setBulkAction={setBulkAction}
            fetchCases={fetchCases}
          />
        )}
      </Content>
    </>
  );
};

export default UserRolesAndRightsHOComponent(CasesComponent);