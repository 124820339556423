import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, InputNumber, Button, Row, Col, Typography, message } from "antd";
import styled from "styled-components";
//import icons
import LogoIcon from "../assets/icons/LogoIcon";

import { verifyAuthenticationOtp,resendOtpCode} from "../api/UserTypeAPI";

const { Text } = Typography;
const StyledDivWrapper = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const StyledDivImageWrapper=styled.div`
display: flex;
justify-content: center;
align-items: center;
margin: auto;
`;


const StyledRow=styled(Row)`
.ant-col {
    margin: 10px 0px;
  }
`;

const VerificationOTP = (props) => {
  const [otpValue, setOtpValue] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

    function resendOtpVerification() {
      const data = {
        _id: window.localStorage.getItem("_id"),
       type: window.localStorage.getItem("type"),
      };
      resendOtpCode(data)
        .then((res) => {
          const { data } = res;
          if (data && data.payload) {
            message.success(data.payload.sendVia);
          } else {
            message.error("Unable to send OTP");
          }
        })
        .catch(() => {
          message.error("Unable to send OTP");
        });
    }

  function onChange(value) {
    setOtpValue(value);
  }

  function onVerifyOtp() {
    const data = {
      otp: otpValue,
      _id: window.localStorage.getItem("_id"),
      type: window.localStorage.getItem("type"),
    };
    setLoading(true);
    verifyAuthenticationOtp(data).then((res) => {
        verifyComplete();
        const { data } = res;
        if (data&&data.token) {        
            const { token, type, userEmail } = data;
            localStorage.setItem("userToken", token);
            localStorage.setItem("userEmail", userEmail);
            localStorage.setItem("type", type); 
            history.push("/");           
            
        } else {
          verifyComplete();
          message.error("Unable to verify the OTP");
        }
      })
      .catch((error) => {
        verifyComplete();      
        const { response } = error;
        if (response) {
          const { data } = response;
          if (data && data.message) {
            message.error(data.message);
          } else {
            message.error("Unable to verify the OTP");
          }
        } else {
          message.error("Unable to verify the OTP");
        }
      });
  };
  

  const verifyComplete = () => {
    setLoading(false);
  };
  return (
    <StyledDivWrapper>
      <Card style={{ width: 320 ,background:"white"}}>
          <StyledDivImageWrapper>
        <LogoIcon
          width={"50%"}     
        />
        </StyledDivImageWrapper>

        <StyledRow gutter={24} >
          <Col span={24}>
            <Text>
              <b>Enter the One Time Password (OTP) sent to your E-mail or Phone.</b>
            </Text>
          </Col>

          <Col span={24}>
            <InputNumber
              onChange={onChange}
              placeholder="Enter One Time Password"
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={24}>
            <Button
              type="primary"
              style={{ width: "100%" }}
              loading={loading}
              onClick={() => onVerifyOtp()}
            >
              Verify OTP
            </Button>
          </Col>
          <Col span={24}>
            <Text>
              <b>OTP is valid for 10 minutes</b>
            </Text>
          </Col>
          <Col span={24}>
            <Text
                onClick={() => resendOtpVerification()}
              style={{ cursor: "pointer", color: "#7bad7e" }}
            >
              <b>Resend OTP</b>
            </Text>
          </Col>
        </StyledRow>
      </Card>
    </StyledDivWrapper>
  );
};

export default VerificationOTP;
