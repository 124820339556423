import { useState, useEffect, useMemo } from 'react'
import { debounce } from 'lodash'

import { create, update, deleteOne, getMany } from "../../../../api/TemplateAPI"
import * as LookUpAPI from '../../../../api/LookUpAPI';
import * as PhysiciansAPI from '../../../../api/PhysiciansAPI'
import { message } from 'antd'

export function useTemplates(type) {
    const [loading, setLoading] = useState(false)
    const [selectedTemplate, selectTemplate] = useState(null)
    const [templates, setTemplates] = useState([])
    const [telemedicineCategories, setTelemedicineCategories] = useState([])
    const [caseStatuses, setCaseStatues] = useState([])
    const [physicians, setPhysicians] = useState([])

    const [templateFilters, setTemplateFilters] = useState({
        owner: null,
        categoryIds: null,
        status: null,
        search: null,
        sortBy: 'title',
        sortDirection: 'asc'
    });

    useEffect(() => {
        try {   
            LookUpAPI.lookUp('tele-medicine-category')
                .then(({ data }) => setTelemedicineCategories(data.payload))
            LookUpAPI.lookUp('case-status')
                .then(({ data }) => setCaseStatues(data.payload))
            PhysiciansAPI.getPhysiciansV2()
                .then(({ data }) => setPhysicians(data.physicians))
        } catch (e) {
            console.error(e)
        }
    }, [])

    const searchTemplates = async params => {
        setLoading(true)
        setTemplates([])

        if (type === 'prescription') delete params.caseStatus

        try {
            const { data: { templates } } = await getMany(params)

            if (!templates?.length) return setTemplates([])

            const mappedTemplates = templates.map(template => {
                const additions = {
                    teleMedicineCategories: template.teleMedicineCategoryIds?.map(category => category.name).join(', '),
                    ...template.statusId && { caseStatus:template.statusId.name}
                }

                Object.assign(template, additions)
                return template
            })

            setTemplates(mappedTemplates)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const debouncedSearchTemplates = useMemo(() => debounce(params => searchTemplates({ ...params }), 1000), [])

    const createTemplate = async ({ 
        title, 
        pharmacyNotes, 
        directions, 
        teleMedicineCategoryIds,
        ...medication 
    }) => {
        try {
            const payload = {
                title,
                pharmacyNotes,
                directions,
                teleMedicineCategoryIds,
                medication
            }

            await create(type, payload);
            message.success('Template successfully created')
            searchTemplates({type, ...templateFilters})
        } catch (e) {
            console.log(e)
            message.error('Error creating template')
        }
    }

    const editTemplate = async (
        templateId, { 
            title, 
            pharmacyNotes, 
            directions, 
            teleMedicineCategoryIds,
            ...medication 
        }
    ) => {
        try {
            const payload = {
                templateId,
                title,
                medication,
                pharmacyNotes,
                directions,
                teleMedicineCategoryIds,
            }

            await update(templateId, payload);
            message.success('Successfully updated template')
            searchTemplates({type, ...templateFilters})
        } catch (e) {
            console.error(e)
            message.error('Error editing template')
        }
    }

    const deleteTemplate = async templateId => {
        try {
            await deleteOne(templateId);
            searchTemplates({type, ...templateFilters})
        } catch (e) {
            console.error(e)
            throw new Error(`Error deleting template`)
        }
    }

    useEffect(() => {
        setTemplates([])
        searchTemplates({type, ...templateFilters})
    }, [templateFilters, debouncedSearchTemplates])

    useEffect(() => {
        setTemplateFilters({
            owner: type === 'prescription' ? 'admin' : null,
            categoryIds: null,
            status: null,
            search: null,
            sortBy: 'title',
            sortDirection: 'asc'
          })
        setTemplates([])
    }, [type])

    return {
        selectTemplate,
        setTemplates,
        createTemplate,
        editTemplate,
        deleteTemplate,
        setTemplateFilters,
        searchTemplates,
        telemedicineCategories,
        caseStatuses,
        physicians,
        type,
        selectedTemplate,
        loading,
        templates,
        templateFilters
    };
}
