import { useEffect, useState } from 'react';
import { lookUp } from '../api/LookUpAPI';
import config from '../config';

/**
 * @param {'client'|'case-status'|'state'|'tele-medicine-category'|'credential'|'physician-group'|'article-category'|'physician-tags'} type 
 * @returns {Array}
 */
export const useLookup = (type) => {
  const [resources, setResources] = useState([]);

  useEffect(() => {
    lookUp(type).then(({ data }) => setResources(data.payload));
  }, [type])

  return resources;
}
