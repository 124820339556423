import { LoadingOutlined } from "@ant-design/icons";
import { message, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";

import { updateTicket } from "../../api/CaseTicketsAPI";
import { getUsersListByStatus } from "../../api/UserTypeAPI";

const getAdminsDropDownList = (currentAdmin, adminsList) => (
    [
        currentAdmin,
        ...adminsList.map(admin => dropDownItem(admin))
    ]
);

const dropDownItem = (admin, prefix, symbolPrefix, symbolSuffix) => {

    const labelWrapper = (content) =>
        prefix && symbolPrefix
            ? `${ prefix } ${ symbolPrefix } ${ content } ${ symbolSuffix }` :
            content;

    return {
        value: admin._id,
        label: `${ labelWrapper(`${ admin.firstName } ${ admin.lastName }`) }`
    }
}

const CaseAssignComponent = ({ currentAdminInfo, ticketId, assignedRep }) => {
    const [ adminsDropDown, setAdminsDropDown ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const [ admins, setAdmins ] = useState([])
    const [ adminByRepId, setAdminByRepId ] = useState(null)

    const getAdminsList = (physicians) => {
        const list = {};
        physicians.forEach((physician) => {
            const { _id } = physician;
            list[_id] = physician;
        });
        return list;
    }

    useEffect(() => {
        const fetchAdmins = async () => {
            try {
                const { data } = await getUsersListByStatus('active')

                let assignedToTicketAdmin = null;
                let currentAdmin = null;

                const otherAdmins = data.payload.reduce((result, admin) => {
                    if (admin.email === assignedRep?.email) {
                        assignedToTicketAdmin = admin;
                    }

                    if (admin.email === currentAdminInfo.userEmail) {
                        currentAdmin = admin;
                        return result;
                    }

                    result.push(admin);
                    return result;
                }, []);

                if (assignedToTicketAdmin) setAdminByRepId(assignedToTicketAdmin._id);
                setAdminsDropDown(getAdminsDropDownList(
                    dropDownItem(currentAdmin, 'me', '(', ')'),
                    otherAdmins
                ))
                setAdmins(getAdminsList(data.payload));
            } catch (e) {
                message.error("Unable to fetch admin users");
            } finally {
                setLoading(false);
            }
        };

        void fetchAdmins();
    }, [])

    const handleTakeAssignment = async (adminId) => {
        const admin = admins[adminId];
        setLoading(true);
        try {
            await updateTicket({ rep: { userEmail: admin.email }, ticketId })
            setAdminByRepId(admin._id);
            message.success(`Ticket has been assigned to ${ admin.firstName } ${ admin.lastName } successfully`)
        } catch (e) {
            message.error('Error taking assignment of ticket')
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            Assigned to: <Spin spinning={ loading } indicator={ <LoadingOutlined/> }>

            <Select showSearch
                    optionFilterProp="label"
                    options={ adminsDropDown }
                    onChange={ handleTakeAssignment }
                    value={ adminByRepId }
                    placeholder="Please select"
                    style={ { width: 170, marginTop: -5 } }>
            </Select>
        </Spin>
        </>
    )
};

export default CaseAssignComponent;