//import packages
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Badge,
  Button,
  Col,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
  Typography,
} from "antd";
import { EditOutlined, LoadingOutlined, MessageOutlined, } from "@ant-design/icons";
import styled from "styled-components";

//import components
import UserRolesAndRightsHOComponent
  from "../../components/UserRolesAndRightsHOComponent/UserRolesAndRightsHOComponent";

//import other files and API function
import authorization from "../../auth/authorization";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import { getTicket, markTicketAsRead, updateTicket, } from "../../api/CaseTicketsAPI";
import { getUsersList } from "../../api/UserTypeAPI";
import { CSVLink } from "react-csv";
import { usePhysicians } from '../../hooks/usePhysicians';
import { useLookup } from '../../hooks/useLookup';
import { useLocalStorage } from "../../utils/useLocalStorage";

const { Content } = Layout;
const { TabPane } = Tabs;

const StyledTabWrapper = styled.div`
  width: 100%;
  max-width: 1480px;
  margin: 24px auto;
  @media (max-width: 1800px) {
    max-width: 1300px;
  }
  @media (max-width: 1550px) {
    max-width: 1080px;
  }
  @media (max-width: 1300px) {
    max-width: 950px;
  }
  @media (max-width: 1199px) {
    max-width: 780px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 712px;
  }
  @media (max-width: 720px) {
    max-width: 372px;
  }
`;

const StyledComponentWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
  max-width: 1480px;
  padding: 24px 28px;
  @media (max-width: 1800px) {
    max-width: 1300px;
  }
  @media (max-width: 1550px) {
    max-width: 1150px;
  }
  @media (max-width: 1300px) {
    max-width: 950px;
  }
  @media (max-width: 1199px) {
    max-width: 780px;
  }
  @media (max-width: 788px), (max-width: 922px) {
    max-width: 712px;
  }
  @media (max-width: 720px) {
    max-width: 372px;
  }
`;

const StyledTabs = styled(Tabs)`
  > .ant-tabs-bar {
    margin: unset;
  }
  > .ant-tabs-bar
    > .ant-tabs-nav-container
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-scroll
    > .ant-tabs-nav
    > div
    > .ant-tabs-tab-active {
    background: #ffffff;
    border-bottom: unset;
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin: 5px 0 0 0;
`;

const StyledButton = styled(Button)`
  width: 80px;
  margin-top: 30px;
`;

const Title = styled(Typography.Title)`
  && {
    font-size: 36px;
    font-weight: 700;
  }
`;

const StyledCol = styled(Col)`
  display: flex;
  float: right;
  gap: 1em;
  .ant-form-item {
    margin-top: auto;
  }
`;

const ModalForm = styled(Form)`
  padding-bottom: 20px;
`;

const CaseTicketComponent = ({ userType, userDetails, notificationCount }) => {

  const history = useHistory();
  const initialParams = {
    page: 0,
    limit: 10,
    status: "Open",
  };

  const [loading, setLoading] = useState(false);
  const [formFilters, saveFormFilters, removeSavedFilters] = useLocalStorage('case-tickets-filters', initialParams, JSON.parse);
  const [form] = Form.useForm();
  const [ticketData, setTicketData] = useState([]);
  const [rep, setRep] = useState([]);
  const [status, setStatus] = useState("Open");
  const [filterParams, setFilterParams] = useState();
  const [modalLoad, setModalLoad] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [currentStatus, setCurrentStatus] = useState();
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [updateStatus, setUpdateStatus] = useState(null);
  const [tablePageSize, setTablePageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const { physicians } = usePhysicians();
  const caseStatuses = useLookup('case-status');

  const statusOptions = ["Open", "Closed", "Solved"];

  const columns = [
    {
      title: "Ticket Id",
      width: 250,
      dataIndex: "ticketId",
      key: "ticket-id",
    },
    {
      title: "Rep",
      width: 250,
      dataIndex: "repName",
      key: "rep",
    },
    {
      title: "Physician",
      width: 250,
      dataIndex: "physicianName",
      key: "physician",
    },
    {
      title: "Case Id",
      width: 250,
      dataIndex: "caseId",
      key: "case-id",
    },
    {
      title: "Patient Name",
      dataIndex: "patientName",
      key: "patient-name",
      width: 250,
    },
    {
      title: "Last Response Time",
      width: 220,
      dataIndex: "",
      key: "last-response-time",
      render: (eachCase) => (
        <>
          {eachCase.lastResponseTime
            ? dateTimeFormatter(eachCase.lastResponseTime)
            : "NA"}
        </>
      ),
    },
    {
      title: "Status",
      width: 40,
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Update Status",
      width: 260,
      dataIndex: "",
      key: "update-status",
      align: "center",
      render: (eachCase) => (
        <>
          <Button
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => {
              setModalVisibility(true);
              setCurrentStatus(eachCase.status);
              setSelectedTicketId(eachCase.ticketId);
            }}
          />
        </>
      ),
    },
    {
      title: "Category",
      width: 200,
      dataIndex: "categoryName",
      key: "category",
    },

    {
      title: "Message",
      width: 150,
      dataIndex: "",
      key: "message",
      align: "center",
      render: (eachCase) => (
        <Badge count={eachCase.unreadCount}>
          <Button
            shape="circle"
            icon={<MessageOutlined />}
            onClick={async () => {
              await handleMarkTicketAsRead(eachCase.ticketId);
              window.localStorage.setItem("physicianChatKey", true);
              history.push(`/cases/${eachCase.caseId}`);
            }}
          />
        </Badge>
      ),
    },
  ];

  const fetchRep = () => {
    getUsersList().then((res) => {
      if (res.data && res.data.payload) {
        const repData = res.data.payload.map((eachRep) => ({
          key: eachRep._id,
          value: eachRep.firstName + " " + eachRep.lastName,
        }));
        setRep(repData);
      }
    });
  };

  useEffect(() => {
    fetchRep();
    caseTickets(formFilters);
    form.setFieldsValue(formFilters);
    // eslint-disable-next-line
  }, [notificationCount]);

  const caseTickets = (filterValue) => {
    setLoading(true);
    getTicket(filterValue)
      .then((res) => {
        if (res.data.payload) {
          setTotalCount(res.data.totalCount);
          const data = res.data.payload.map((eachRep) => ({
            ...eachRep,
          }));
          setTicketData(data);
        } else {
          message.error("Unable to load Case List. Please try again!");
        }
      })
      .finally(() => setLoading(false))
      .catch((error) => {
        if (error.response.data.message) {
          message.error(error.response.data.message);
        } else {
          message.error("Unable to load Case List. Please try again!");
        }
        setLoading(false);
      });
  };

  const filterValue = () => {
    const data = form.getFieldsValue();
    !data.status ? setStatus("Open") : setStatus(data.status);
    const filter = {};
    filter["page"] = 0;
    filter["limit"] = 10;
    filter["rep"] = data.rep;
    filter["caseId"] = data.caseId;
    filter["caseStatus"] = data.caseStatus
    filter["patientEmail"] = data.patientEmail;
    filter['physicianId'] = data.physicianId;
    setCurrentPage(1);
    setTablePageSize(10);
    !data.status
      ? (filter["status"] = "Open")
      : (filter["status"] = data.status);
    setLoading(true);
    setFilterParams(filter);
    saveLocallyFormFilters();
    caseTickets(filter);
  };

  const saveLocallyFormFilters = () => {
    saveFormFilters(form.getFieldsValue());
  }

  const clearFilterChange = () => {
    form.resetFields();
    removeSavedFilters();
    setLoading(true);
    setCurrentPage(1);
    setTablePageSize(10);
    setFilterParams();
    setStatus("Open");
    caseTickets(initialParams);
  };

  const handleStatusUpdateChange = (event) => {
    setUpdateStatus(event);
  };

  const handleStatusUpdate = () => {
    form.resetFields();
    setModalLoad(true);
    const params = {
      ticketId: selectedTicketId,
      status: updateStatus,
    };
    if (updateStatus !== currentStatus) {
      updateTicket(params)
        .then((res) => {
          setModalLoad(false);
          setLoading(false);
          if (res.data && res.data.success) {
            message.success("Successfully updated ticket");
            caseTickets(initialParams);
            setModalVisibility(false);
          } else {
            message.error(res.data.payload);
          }
        })
        .catch((err) => {
          setModalLoad(false);
          setLoading(false);
          setModalVisibility(false);
          message.error("Error occured while updating case status");
        });
    } else {
      message.warning("Choose a different Case Ticket Status for updating");
      setModalLoad(false);
      setModalVisibility(false);
    }
  };

  const handleMarkTicketAsRead = async (ticketId) => {
    await markTicketAsRead(ticketId);
  };

  const handleCancel = () => {
    setModalVisibility(false);
    setUpdateStatus("");
  };

  const onChangePage = (value, pageSize) => {
    setLoading(true);
    setCurrentPage(value);
    const paramsData = filterParams ? filterParams : {};
    paramsData["page"] = value - 1;
    paramsData["limit"] = pageSize;
    paramsData["status"] = status;
    caseTickets(paramsData);
  };

  const onPageSizeChange = (current, size) => {
    setTablePageSize(size);
    setCurrentPage(1);
    const paramsData = filterParams ? filterParams : {};
    paramsData["page"] = current - 1;
    paramsData["limit"] = size;
    paramsData["status"] = status;
    caseTickets(paramsData);
  };

  return (
    <>
      <Content>
        <StyledTabWrapper>
          <StyledTabs type="card">
            {authorization.isAllowed(userType, "caseTicketListing") && (
              <TabPane tab="All" key="1">
                <Spin
                  spinning={loading}
                  size="large"
                  indicator={<LoadingOutlined />}
                >
                  <StyledComponentWrapper>
                    <Row>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Title level={2}>All Tickets</Title>
                      </Col>
                    </Row>
                    <Form form={form} name="ticket-details" layout={"vertical"}>
                      <Row gutter={24} style={{ marginBottom: 10 }}>
                        <Col xs={24} sm={24} md={24} lg={5} xl={6}>
                          <StyledFormItem name="rep" label="Rep">
                            <Select
                              placeholder="Filter by Rep"
                              getPopupContainer={(trigger) =>
                                trigger.parentElement
                              }
                              onChange={filterValue}
                            >
                              {rep &&
                                rep.map((eachRep) => (
                                  <Select.Option
                                    key={eachRep.key}
                                    value={eachRep.value}
                                  >
                                    {eachRep.value}
                                  </Select.Option>
                                ))}
                            </Select>
                          </StyledFormItem>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={5} xl={6}>
                          <StyledFormItem name="status" label="Status">
                            <Select
                              placeholder="Filter by Status"
                              defaultValue="Open"
                              getPopupContainer={(trigger) =>
                                trigger.parentElement
                              }
                              onChange={filterValue}
                            >
                              {statusOptions &&
                                statusOptions.map((status) => (
                                  <Select.Option key={status} value={status}>
                                    {status}
                                  </Select.Option>
                                ))}
                            </Select>
                          </StyledFormItem>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={7} xl={6}>
                          <StyledFormItem name="caseId" label="Case Id">
                            <Input onChange={saveLocallyFormFilters} />
                          </StyledFormItem>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={7} xl={6}>
                          <StyledFormItem
                            name="caseStatus"
                            label="Case Status"
                          >
                            <Select showSearch optionFilterProp="value" allowClear onChange={saveLocallyFormFilters}>
                              {caseStatuses.map(caseStatus => {
                                return <Select.Option key={caseStatus._id} value={caseStatus.name}>{caseStatus.name}</Select.Option>
                              })}
                            </Select>
                          </StyledFormItem>
                        </Col>
                      </Row>
                      <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} lg={7} xl={6}>
                          <StyledFormItem
                            name="physicianId"
                            label="Physician"
                          >
                            <Select showSearch optionFilterProp="children" allowClear onChange={saveLocallyFormFilters}>
                              {physicians.map(physician => {
                                return <Select.Option key={physician._id} value={physician._id}>{physician.firstName} {physician.lastName}{!!physician.credential && `, ${physician.credential}`}</Select.Option>
                              })}
                            </Select>
                          </StyledFormItem>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={7} xl={6}>
                          <StyledFormItem
                            name="patientEmail"
                            label="Patient Email Address"
                          >
                            <Input onChange={saveLocallyFormFilters}/>
                          </StyledFormItem>
                        </Col>
                      </Row>
                      <Row justify="space-between">
                        <Col>
                          <StyledFormItem>
                            <StyledButton
                              style={{
                                margin: "10px 5px 10px 0",
                                width: "initial",
                              }}
                              type="primary"
                            >
                              <CSVLink
                                data={ticketData}
                                filename={`${Date.now()}_exported-case-tickets.csv`}
                                target="_blank"
                              >
                                Export to CSV
                              </CSVLink>
                            </StyledButton>
                          </StyledFormItem>
                        </Col>
                        <Col>
                          <Row>
                            <StyledFormItem>
                              <StyledButton
                                style={{ margin: "10px 5px 10px 0" }}
                                type="primary"
                                onClick={filterValue}
                              >
                                Search
                              </StyledButton>
                            </StyledFormItem>
                            <StyledFormItem>
                              <StyledButton
                                style={{ margin: "10px 0 10px 5px" }}
                                type="secondary"
                                onClick={clearFilterChange}
                              >
                                Clear
                              </StyledButton>
                            </StyledFormItem>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                    <Modal
                      visible={modalVisibility}
                      destroyOnClose={true}
                      footer={[]}
                      onOk={() => setModalVisibility(false)}
                      onCancel={() => handleCancel()}
                    >
                      <Spin
                        spinning={modalLoad}
                        indicator={<LoadingOutlined />}
                      >
                        <ModalForm
                          form={form}
                          name="update status"
                          onFinish={handleStatusUpdate}
                        >
                          <StyledFormItem
                            label="Case Ticket Status"
                            name="case-ticket-status"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            required={true}
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              placeholder="Select Case Ticket Status"
                              onChange={handleStatusUpdateChange}
                              value={statusOptions}
                            >
                              {statusOptions &&
                                statusOptions.map((status) => (
                                  <Select.Option key={status} value={status}>
                                    {status}
                                  </Select.Option>
                                ))}
                            </Select>
                          </StyledFormItem>
                          <StyledCol>
                            <StyledFormItem>
                              <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                              >
                                Update Status
                              </Button>
                            </StyledFormItem>
                            <StyledFormItem>
                              <Button size="large" onClick={handleCancel}>
                                Cancel
                              </Button>
                            </StyledFormItem>
                          </StyledCol>
                        </ModalForm>
                      </Spin>
                    </Modal>
                    <Col span={24}>
                      <Table
                        columns={columns}
                        dataSource={ticketData}
                        pagination={{
                          total: totalCount,
                          showTotal: (totalCount) =>
                            `Total ${totalCount} Case Tickets`,
                          current: currentPage,
                          pageSize: tablePageSize,
                          pageSizeOptions: ["5", "10", "20", "50", "100"],
                          showSizeChanger: true,
                          onChange: onChangePage,
                          onShowSizeChange: onPageSizeChange,
                        }}
                        scroll={{ x: 660 }}
                      />
                    </Col>
                  </StyledComponentWrapper>
                </Spin>
              </TabPane>
            )}
          </StyledTabs>
        </StyledTabWrapper>
      </Content>
    </>
  );
};

export default UserRolesAndRightsHOComponent(CaseTicketComponent);
